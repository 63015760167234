.layout {
  color: var(--txt-color);
  /* overflow-x: hidden; */
  /* width: 100%; */
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding: 30px;
}
