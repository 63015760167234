.topnav {
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--topnav-height);
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: var(--primary-main-color);
}

.topnav__MenuIcon {
  font-size: 2rem !important;
  overflow: hidden;
  cursor: pointer;
  color: var(--txt-white) !important;
}

.topnav__right {
  display: flex;
  align-items: center;
}

.topnav__right-item ~ .topnav__right-item {
  margin-left: 30px;
}

.topnav__right__dropdown__menu button {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-size: 1.1rem;
  font-weight: normal !important;
  justify-content: space-between !important;
  width: 100%;
}
.topnav__right__dropdown__menu i {
  margin-left: 5px;
}
.topnav__right__dropdown__menu button:hover {
  color: var(--primary-main-color);
}
.topnav__right-user {
  font-size: 2rem !important;
  color: var(--txt-white) !important;
}

.dropdown {
  position: relative;
  z-index: 99;
}
