@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --primary-main-color: rgb(0, 69, 132);
  --primary-light-color: rgba(0, 69, 132, 0.8);
  --secondary-main-color: rgb(52, 58, 64);
  --secondary-light-color: rgba(52, 58, 64, 0.8);
  --box-shadow: rgba(18, 24, 31, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --sidebar-width: 270px;
  --border-radius: 15px;
  --topnav-height: 70px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --table-hover: #f83245;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
  width: 0px;
}
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--secondary-main-color);
}

ul {
  list-style-type: none;
}

label {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
  display: block !important;
}
.page-header {
  margin-bottom: 40px !important;
  text-transform: capitalize;
}

.Button {
  width: 100px;
  padding: 5px;
  background-color: var(--primary-main-color) !important;
  border-radius: 5px;
  cursor: pointer;
  color: var(--txt-white) !important;
  font-size: 16px;
  border: 1px solid var(--primary-main-color) !important;
  white-space: nowrap;
}
.Button:hover {
  color: var(--primary-main-color) !important;
  background-color: var(--main-bg) !important;
}

.card {
  padding: 30px;
  margin: 0px 0px 30px 0px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.spec__card {
  margin: 0px 0px 30px 0px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 20px;
  color: var(--primary-main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}
.spec__card i {
  cursor: pointer;
}
.spec__options {
  display: flex;
  flex-direction: column;
}
.spec__options button {
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: normal !important;
  justify-content: space-between !important;
  width: 100%;
}
.spec__options i {
  margin-left: 5px;
}
.spec__options button:hover {
  color: var(--primary-main-color);
}
.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.grid-input {
  display: flex;
  flex-direction: column;
}

.newButton {
  position: "absolute";
  right: "10px";
  white-space: nowrap;
}
.addFields {
  cursor: pointer;
  padding: 10px;
  border: 1px solid black !important;
  border-radius: 5px;
  font-size: 15px;
  margin: 15px 0px !important;
}
.field_icon {
  margin-right: 10px;
}
.field_form {
  margin: 15px 0px;
  display: flex;
}
.service-card {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0px 0px 10px 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.search-card {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: 0px 0px 10px 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.search-card label {
  cursor: pointer;
}
.search-card:hover {
  color: var(--primary-main-color);
}
.search__history {
  display: block;
  margin: 6px 0 !important;
}
.search__history:hover {
  color: var(--primary-main-color);
}
.company-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0 0;
  margin: 0px 0px 10px 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 10px;
}
.company-card img {
  height: 150px;
  width: 150px;
  border-radius: var(--border-radius) 0 0;
}
.company-name {
  text-transform: capitalize;
  font-size: 1rem;
}
.social__media__logo {
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
}
.social__media__logo.active {
  border: 1px solid var(--primary-light-color);
}
.social__media__logo > i {
  pointer-events: none;
}
.social__media__icon {
  font-size: 23px;
}
.marker-btn {
  cursor: pointer;
  font-size: large;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #d00;
  background-color: transparent !important;
}
.navigation-control {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}
.map__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.current__location {
  position: absolute !important;
  right: 1rem !important;
  bottom: 2rem !important;
  box-shadow: var(--box-shadow);
  padding: 10px;
  border-radius: 10px;
  background: var(--main-bg);
  color: var(--secondary-main-color);
}
.ads__holder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.ads__card {
  background-color: var(--secondary-main-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
}
.image__card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 10px;
}
.image__delete__icon {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
}
/* .product__image {
  width: 100% !important;
  height: 100% !important;
} */
@media only screen and (max-width: 918px) {
  .ads__holder {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}

.front {
  background-image: url("../images/ad.svg");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  color: var(--txt-white) !important;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  /* border: 4px solid black */
}

.card_title {
  padding-top: 30px;
  color: var(--txt-white);
  letter-spacing: 0.02em;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 25px;
}

.card_subline {
  padding-top: 205px;
  color: var(--secondary-main-color);
  font-size: 1.5em;
  line-height: 25px;
  letter-spacing: 0.02em;
  font-weight: 600;
}

.card_headline {
  line-height: 23px;
}

.card_arrow {
  transition: all 0.35s linear;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--secondary-main-color);
  transition: all 0.2s linear;
  position: absolute;
  right: 40px;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--txt-white) !important;
  z-index: 2;
}
.card_arrow:hover {
  transform: scale(1.4);
}
.forget__password {
  cursor: pointer;
  color: var(--secondary-main-color);
}

.companyList {
  width: 100px;
  height: 100px;
}
.news__list__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .news__list__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .news__list__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
.imageWrapper {
  overflow: hidden;
}
.news__list__wrapper .companyGrid {
  background-color: var(--main-bg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.news__body {
  flex-grow: 1;
}
.news__body:hover .imageGrid {
  transform: scale(1.2);
  transition: transform 330ms ease-in-out;
}
.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: var(--primary-main-color);
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture i {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.9s ease 0s;
  font-size: 7rem;
  color: var(--txt-white);
}

.our-team .title {
  display: block;
  font-size: 20px;
  color: var(--txt-white);
  text-transform: capitalize;
}

.our-team .name {
  display: block;
  font-size: 35px;
  color: var(--txt-white);
  text-transform: capitalize;
}
.product__image__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
